import * as React from "react"
import { useRef, useEffect,useState } from "react";
import { getImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import CallUs from "../components/callUsStrap"
import { convertToBgImage } from "gbimage-bridge"
import SectionTitle from "../components/titleBlock"
import BackgroundImage from 'gatsby-background-image'

const TitleBlockStandard = ({currentLocation,sectionTitle, pageTitle, pageSubTitle, pageTitleBody, img,cta,date}) => {
  const loadedImage = getImage(img)
  const bgImage = convertToBgImage(loadedImage);
  const ref = useRef(null);

  let ctaContent; 
  let headingTag;
  let blogLink;

  if (currentLocation) { 
    if (currentLocation.pathname.includes("blog")) {
      blogLink = <Link to="/blog" className="btn btn-primary rounded-0 me-3 mb-3  " href="/blog/">Back to Blog</Link>
    }
  }

  if (cta){
    ctaContent = <div className="d-grid d-md-block  "><Link to="/contact" className="btn  btn-primary rounded-0 me-3 mb-3 mb-md-0 " href="/contact">Get in touch</Link><CallUs  accent={'secondary'} /></div>;
  }

  //render title, only if title has a value
  if (pageTitle!="") {
    headingTag = <h1 className="fw-light" dangerouslySetInnerHTML={{ __html: pageTitle }} />
  } 



  const [height, setHeight] = useState();


    useEffect(() => {
      const element = ref.current;

      const outerHeight = (el) => {
        var height = el.offsetHeight;
        var style = window.getComputedStyle(el);
        height += parseInt(style.marginTop) + parseInt(style.marginBottom);
        return height;
      };
      
      function handleResizeTitleBlock() {
        let h = outerHeight(element);
        setHeight(h);
      }

      handleResizeTitleBlock();
      window.addEventListener('resize', handleResizeTitleBlock)

      return () => {
        };
        
    }, []);

  return (
    <div
      className="container-fluid bg-light py-5 py-md-0 titleBlock"
      style={{  minHeight:  height  +'px' }}
    >
      <div className="container">
        <div className="row d-flex flex-column flex-md-row">
          <div className="col " >
              <div className="row" ref={ref}>
                <div className="col-12 col-md-11">
                  <div className="py-md-5 my-md-5">
                    <div className="py-5 my-5"
                      data-sal-duration="500"
                      data-sal="slide-up"
                      data-sal-delay="300"
                      data-sal-easing="ease"
                    >
                    <SectionTitle title={sectionTitle} />
                    {blogLink}
       
                    
                   {headingTag}
                    <h2
                      className="fw-light fs-3 my-4"
                      dangerouslySetInnerHTML={{ __html: pageSubTitle }}
                    />
                     {date !== null && (  
                     <time datetime={date}><p className="fw-normal">{date}</p></time>
                    )}
                      <p className="fw-normal">{pageTitleBody}</p>
                    {ctaContent}
                   
                    
                    
             

                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div
            className="col position-relative pageTitleBGOuter"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <div className="gridbreaker position-absolute d-none d-md-block">
              
               <BackgroundImage
                className=""
                    // Spread bgImage into BackgroundImage:
                {...bgImage}
                
                    style={{
                        // Defaults are overwrite-able by setting one or each of the following:
                      width:'100%',
                      minHeight: height+'px'
                    }}
                ></BackgroundImage>
            </div>
            <div className="row pageMobileBGimageWrapper d-block d-md-none">
              <BackgroundImage
                className=""
                    // Spread bgImage into BackgroundImage:
                    {...bgImage}
                    style={{
                        // Defaults are overwrite-able by setting one or each of the following:
                      width:'100%',
                      height: '300px',
                    }}
                ></BackgroundImage>
            </div>
          </div>

          

          </div>
        </div>
      </div>

    )
}

export default TitleBlockStandard
