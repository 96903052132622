import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
const Terms = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPageprivacypolicyJson.edges[0].node;
  return (
    <Layout>
      <Seo
        title="Privacy Policy | TKing Associates"
        description="Read the Tkings Privacy Policy >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <div className="container">
        <div className="row">
          <div className="col my-5">
              <p>We respect privacy and your rights to control your personal data. Our principle guidelines are simple: We will be clear about the data we collect and why. Your data is sacredly protected by us. We do not and will not sell your data to third parties.</p>
              <p>This Privacy Statement (the ‘Statement’) explains the personal information we collect from you, either directly or indirectly and how we will use and process your information. The Statement also covers the choices you can make about the data we collect and how you can control these decisions.<br/>
              We also give more detail about:</p>
              <ul>
              <li>How we use personal information</li>
              <li>Sharing and disclosure</li>
              <li>How to access and control your personal information</li>
              <li>Other important privacy information, and</li>
              <li>How to contact us&nbsp;regarding your information</li>
              </ul>
              <p><strong>The Personal Information We Collect</strong></p>
              <p>As you gather more information about T King Associates Ltd, you may virtually or physically visit a site that we own. Examples include browsing our website, calling our in-house sales representatives or attending T King Associates Ltd events and shows at which we are exhibiting. To learn more about our services, you may voluntarily provide personal information, such as your name and email address during these interactions. You may also provide information online via&nbsp;<a href="http://www.sonos.com/en-gb/legal/privacy#cookies">cookies or similar technologies</a>&nbsp;when you visit our website.</p>
              <p><strong>We Collect:</strong>&nbsp;Name, email address, IP address, and information provided by cookies or similar technology.</p>
              <p><strong>Why:</strong>&nbsp;We use this information to share news about events and products offered by T King Associates Ltd. Additionally, your IP address helps us to understand geographic information about our website visitors better so that we can improve our website and accessibility for everyone.</p>
              <p><strong>How we use Personal Information</strong></p>
              <p>T King Associates Ltd uses the data we collect to provide you with the services we offer. We also use the data to communicate with you, for example, informing you about your account, new products or services available, security and other types of business updates.</p>
              <p><strong>Sharing and Disclosure</strong></p>
              <p>T King Associates Ltd does not, and will not sell personal information about our customers.&nbsp;We only disclose your data as authorised in this statement. We do not share information with third parties without first obtaining your express permission.</p>
              <p><strong>How to Access and Control your Personal Information</strong></p>
              <p>You can review, edit or delete your personal data by contacting us directly to&nbsp;hello@tkingassociates.com</p>
              <p>&nbsp;</p>
              <p><strong>Other Important Privacy Information</strong></p>
              <p><strong>Our Policy Towards Children</strong></p>
              <p>T King Associates Ltd does not target and is not intended to attract children. T King Associates Ltd does not knowingly solicit personal information from children or send them requests for personal information.</p>
              <p>Although visitors of all ages may navigate through our website we do not intentionally collect personal information from those under the age of sixteen. If, following a notification by a parent or guardian, or discovery by other means, a child under sixteen has been improperly registered on this site by using false information, we will delete the child’s personal information from our records.</p>
              <p><strong>Where We Store and Process Personal Data</strong></p>
              <p>Personal data collected by T King Associates Ltd may be stored and processed in any country where T King Associates Ltd or its affiliates, subsidiaries or service providers maintain facilities. T King Associates Ltd uses its own servers and database to store and maintain data.</p>
              <p>All information you provide may be transferred or accessed by entities around the world as described in this Statement. T King Associates Ltd uses approved Standard Contractual Clauses for the international transfers of personal information collected in the European Economic Area and Switzerland.</p>
              <p><strong>Our Retention of Personal Data</strong></p>
              <p>T King Associates Ltd retains personal data for as long as necessary to provide our services and fulfil the transactions you have requested or for other essential purposes such as complying with our legal obligations and resolving disputes or enforcing our agreements. Because these needs can vary for different data types in the context of different services, actual retention periods can vary significantly.</p>
              <p><strong>Changes to Our Privacy Statement</strong></p>
              <p>T King Associates Ltd may modify or update this statement when necessary to reflect customer feedback and changes in our service; so, please review it regularly. When we update this statement, we will revise the ‘Last Update’ date at the top of the statement. If there are material changes to the statement or in how we use your personal data, we will notify you either by posting a notice of such changes before they take effect or by directly sending you a notification. We encourage you to regularly review this statement to learn more how T King Associates Ltd is using and protecting your information. Your continued use of our services after any modification to this statement will constitute your acceptance of such modification and updates.</p>
              <p><strong>How to Contact us&nbsp;regarding your Information</strong></p>
              <p>All requests for information regarding your personal data must be made in writing. If you have any questions about the use of your personal information, please send us an email to&nbsp;<a href="mailto:hello@tkingassociates.com">hello@tkingassociates.com</a></p>
              <p>In order to provide you with details on the personal information we hold, identification will be required to protect customer privacy.</p>
              <p>Unless otherwise stated, T King Associates Ltd. is a data controller for personal data we collect through the services subject to this statement. Our address is 14 Osier Way, Swan Business Centre, Buckingham, MK18 1TB and we can be reached via telephone on 01280 824836 during normal office hours.</p>
              <p><strong>Terms</strong></p>
              <p>If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which govern T King Associates Ltd relationship with you in relation to this website.</p>
              <p>The use of this website is subject to the following terms of use:</p>
              <ul>
              <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
              <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
              <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
              <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
              <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
              <li>Unauthorised use of this website may give to a claim for damages and/or be a criminal offence.</li>
              <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
              </ul>
              <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England and Wales.</p>
              <p><strong>Copyright</strong></p>
              <p>This website and its content is copyright of “T King Associates Ltd” – © “T King Associates Ltd ” 2017. All rights reserved.</p>
              <p>Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following:</p>
              <ul>
              <li>You may print or download to a local hard disk extracts for your personal and non-commercial use only</li>
              <li>You may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material</li>
              </ul>
              <p>You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</p>
              <p><strong>Cookies</strong></p>
              <p>This website uses things called “Cookies” to ensure your experience of the site, and our ability to improve it is enhanced. &nbsp;This web site sets a single “Session ID” cookie, essential for the operation of the web site which is used to ‘remember’ user specific information only during the time that a user is using the web site. This cookie is deleted when you close your browser. No personally identifiable information is stored in the cookie at any time. This is a ‘Category 1’ cookie within European legislation.</p>
              <p>Specifically, the following Cookies are in use:</p>
              <p>-Google Analytics (3rd party). &nbsp;We use this to see how many visitors we are getting, and how they found us. &nbsp;You can find out more about Google Analytics’ Cookies here: &nbsp;<a href="http://www.google.com/policies/privacy/ads/#toc-analytics">http://www.google.com/policies/privacy/ads/#toc-analytics</a></p>
              <p>-Social Media Sharing and Integration (3rd party). &nbsp;We use Facebook and Twitter functionality within this site so that you can share items of interest, or become a fan of our Social Media Profiles. &nbsp;You can find out more about Facebook’s Cookie use here:&nbsp;<a href="http://www.facebook.com/help/?faq=115180708570932">http://www.facebook.com/help/?faq=115180708570932</a>&nbsp;, and Twitter’s here:&nbsp;<a href="https://twitter.com/privacy">https://twitter.com/privacy</a></p>
              <p>– We also sometimes use video embedding, like YouTube or Vimeo (3rd party) to enhance your experience of content on our site. &nbsp;You can find out about YouTube’s Cookie policy here:&nbsp;<a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>, and Vimeo’s here:&nbsp;<a href="http://vimeo.com/privacy">http://vimeo.com/privacy</a></p>
              <p>WordPress uses Cookies to further enhance the experience of the website, which you can read more about , here –&nbsp;<a href="http://codex.wordpress.org/WordPress_Cookies">http://codex.wordpress.org/WordPress_Cookies</a></p>

              </div>
          </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allPageprivacypolicyJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }
}`
export default Terms
