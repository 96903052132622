import * as React from "react"


const SectionTitle = ({title}) => { 

    const hasTitle = title;
    if (hasTitle)
        return (
            <div className="border align-self-start border-secondary d-inline-flex px-4 py-2 mb-4" >
                {title}
            </div>
        )
    return '';
}

export default SectionTitle


